<template>
  <teleport to="body">
    <div class="modal" v-if="showUserModal" @click.self.prevent="showUserModal != showUserModal">
      <div class="wrapper">
        <div class="content">
          <form @submit.prevent.stop="submit">
            <div v-if="signUpShow">
              <h3 class="title">Sign Up</h3>
              <p class="tip">Sign Up is not needed to place an order!</p>
              <label>First Name</label>
              <input type="text" v-model="fname" placeholder="First Name..." required />
              <label>Last Name</label>
              <input type="text" v-model="lname" placeholder="Last Name..." required />
              <label>Email</label>
              <input type="email" v-model="email" placeholder="Customer Email..." required />
              <label>Cell Phone</label>
              <input type="tel" v-model="phone" placeholder="Cell Phone..." required />
              <label>Address</label>
              <input type="text" v-model="address" placeholder="Address..." />
              <label>Password</label>
              <input type="password" v-model="password" placeholder="Password..." required />
              <label> Confirm Password</label>
              <input type="password" v-model="cpassword" placeholder="Confirm Password..." required />
            </div>
            <div v-if="resetPassword">
              <label>Account Email:</label>
              <input type="email" v-model="email" placeholder="Account Email..." required />
            </div>
            <div v-if="!signUpShow && !resetPassword">
              <h3 class="title">Sign In</h3>

              <label>Email</label>
              <input type="email" v-model="email" placeholder="Customer Email..." required />
              <label>Password</label>
              <input type="password" v-model="password" placeholder="Password..." required />
            </div>
            <div v-if="!resetPassword" class="actions">
              <button class="action primary" v-if="signUpShow">Sign Up</button>
              <button class="action primary" v-if="!signUpShow" @click.prevent.stop="login" type="submit">Sign In</button>
              <button class="action cancel" @click.prevent.stop="showUserModal = false">Exit</button>
            </div>
            <div style="text-align: center; margin-bottom: 5px" v-if="!signUpShow && !resetPassword">
              Dont have an account? <button class="primary sign-up" @click.prevent.stop="signUpShow = !signUpShow">Sign Up</button>
            </div>
            <div style="text-align: center" v-if="!signUpShow && !resetPassword">
              Forgot your password? <button class="primary sign-up" @click.prevent.stop="resetPassword = !resetPassword">Reset</button>
            </div>
            <div style="text-align: center" v-if="signUpShow && !resetPassword">Already have an account? <button class="primary sign-up" @click.prevent.stop="signUpShow = false">Sign In</button></div>
            <div v-if="resetPassword" class="actions">
              <button class="action primary" v-if="resetPassword" @click.prevent.stop="reset(email)">Send Password Reset</button>
              <button class="action cancel" v-if="resetPassword" @click.prevent.stop="resetPassword = !resetPassword">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { api } from "@/helpers/api";
import parsePhoneNumber, { validatePhoneNumberLength } from "libphonenumber-js";

export default {
  name: "UserModal",
  props: ["open"],

  data() {
    return {
      showUserModal: false,
      signUpShow: false,
      fname: "",
      lname: "",
      email: "",
      password: "",
      phone: "",
      address: "",
      cpassword: "",
      resetPassword: false,
    };
  },
  watch: {
    open() {
      this.showUserModal = true;
    },
  },
  methods: {
    reset(email) {
      console.log(email);
      api.resetPassword(email);
      this.$notify({
        text: "Password Reset Email Sent",
        type: "success",
      });
      this.resetPassword = false;
      this.showUserModal = false;
    },
    submit() {
      this.signUpShow ? this.signup() : this.login();
    },
    async login() {
      const result = await api.login(this.email, this.password, localStorage.getItem("company"));
      if (result.jwt_token) {
        localStorage.setItem("token", result.jwt_token);
        localStorage.setItem("userID", JSON.stringify(result.user_id));
        this.$store.commit("setUserMutation");
        this.showUserModal = false;
        return true;
      } else {
        this.$notify({
          text: "Incorrect Email or Password",
          type: "error",
          duration: 1500,
        });
        return false;
      }
    },
    async signup() {
      const phoneNumber = parsePhoneNumber(this.phone, "NZ");
      if (validatePhoneNumberLength(this.phone, "NZ") || phoneNumber.isValid() === false) {
        this.$notify({
          text: "Phone Number is in valid",
          type: "error",
          duration: 1500,
        });
        return;
      }

      if (this.password === this.cpassword) {
        const user = {
          fname: this.fname,
          lname: this.lname,
          email: this.email,
          password: this.password,
          phone: this.phone,
          address: this.address,
          company: localStorage.getItem("company"),
          settings: {
            theme: "os",
          },
        };
        const res = await api.signup(user);
        console.log(res);
        if (res == "error") {
          this.signUpShow = false;
          this.$notify({
            text: "Account Exists",
            type: "error",
            duration: 1500,
          });
          return;
        }
        const jsonRes = JSON.parse(res);

        if (jsonRes.ok) {
          this.signUpShow = false;
          this.$notify({
            text: "Account Created",
            type: "success",
            duration: 1500,
          });
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "public/wrapper";

.tip {
  padding: 0rem;
  margin-top: 0rem;
}

.sign-up {
  margin-left: 10px;
  padding: 0.25rem;
  font-size: 0.75rem;
}
</style>
