<template>
  <div class="parent" :class="{ closed: getClosed }">
    <div class="info">
      <div>
        <img :src="shopLogo" />
        <h2>{{ shopName }}</h2>
        <p>{{ shopDesc }}</p>
      </div>
    </div>
    <div v-if="this.webStore && this.getClosed">
        <h1>Site is currently closed</h1>
      </div>
    <div class="user-icon" v-if="webStore" @click="accountOpen">
      <!-- <div class="cart-count">{{ cartCount }}</div> -->
      <font-awesome-icon :class="user.fname ? 'active icon' : 'icon'" icon="user" />
      <div v-if="user.fname">Hi, {{ user.fname }}</div>
      <div v-if="!user.fname">Sign In</div>
    </div>
  </div>
  <UserModal :open="showUserModal" />
  <UserAccountModal :open="showUserAccountModal" :account="user" v-if="user.fname" />
</template>

<script>
import { mapGetters } from "vuex";
import UserModal from "../User/UserModal.vue";
import UserAccountModal from "../User/UserAccountModal.vue";

export default {
  data() {
    return {
      companyName: window.localStorage.getItem("companyName"),
      shops: JSON.parse(window.localStorage.getItem("shops")),
      site: window.localStorage.getItem("site"),
      showUserModal: false,
      showUserAccountModal: false,
    };
  },
  components: {
    UserModal,
    UserAccountModal,
  },
  watch: {
    user: {
      handler() {
        if (Object.keys(this.user).length > 0 && this.user.settings.theme) {
          document.documentElement.classList.add(`${this.user.settings.theme}-theme`);
        } else {
          document.documentElement.classList.remove(`os-theme`);
        }
      },
      deep: true,
    },
    
  },
  computed: {
    ...mapGetters({
      getClosed: "getClosed",
      user: "user",
      webStore: "webStore",
    }),
    shopName() {
      let name;
      for (let shop in this.shops) {
        ////console.log(shop, this.site)
        if (shop === this.site) {
          name = this.shops[shop].site_name;
        }
      }
      return name;
    },
    shopDesc() {
      let desc;
      for (let shop in this.shops) {
        ////console.log(shop, this.site)
        if (shop === this.site) {
          desc = this.shops[shop].site_description;
        }
      }
      return desc;
    },
    shopLogo() {
      let desc;
      for (let shop in this.shops) {
        if (shop === this.site) {
          desc = this.shops[shop].logo;
          console.log(this.shops[shop]);
        }
      }
      return desc;
    },
    
  },
  methods: {
    accountOpen() {
      Object.keys(this.user).length > 0 ? (this.showUserAccountModal = !this.showUserAccountModal) : (this.showUserModal = !this.showUserModal);
    },
  },
};
</script>
<style scoped lang="scss">
.closed {
  background-color: var(--warning-colour);
}

.parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    img {
      max-width: 15rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    h2 {
      margin: 0;
    }

    p {
      margin: 0;
      text-decoration: none;
    }
  }
  
  .user-icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .active {
      color: var(--action-colour);
    }
    &:hover {
      color: var(--action-colour);
    }
    .icon {
      height: 2.5rem;
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}
</style>
