<template>
  <div v-if="!webStore" id="menu-wrapper">
    <div class="pages-menu">
      <svg
        :class="(socketConnected ? 'active' : 'disconnected') + ' icon'"
        viewBox="0 0 115 105"
        @click="sendNote"
      >
        <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1">
          <path
            class="cls-1"
            d="m 106.30117,27.813486 a 7.0284566,6.784241 0 0 0 -4.32214,1.431849 49.004943,47.302184 0 0 0 -89.233142,-0.370747 7.0637755,6.8183328 0 0 0 -10.8517271,5.765753 v 24.644012 a 7.0637755,6.8183328 0 0 0 7.063777,6.818332 6.9710634,6.7288421 0 0 0 2.5473721,-0.464499 49.031432,47.327753 0 0 0 91.63041,-0.259949 7.0637755,6.8183328 0 0 0 10.22924,-6.102408 V 34.640341 a 7.0637755,6.8183328 0 0 0 -7.06379,-6.826855 z M 24.816114,69.3073 a 2.0793989,2.0071467 0 0 1 -0.322285,-1.235821 l 0.114783,-1.644924 a 0.18100925,0.17471978 0 0 1 0.339944,-0.0767 c 1.099299,1.892087 5.125652,8.386549 10.2204,11.318432 a 25.217679,24.341448 0 0 0 3.200773,1.278437 10.873799,10.495971 0 0 1 -3.200773,-1.278437 C 32.171266,76.262008 27.725503,73.615643 24.81611,69.307308 Z m 42.157494,7.806992 H 50.457619 A 26.537722,25.615624 0 0 1 29.155921,66.886793 c -4.57821,-5.927688 -8.617807,-14.940671 -8.617807,-28.304604 0,0 1.373023,-10.84541 13.593353,-10.84541 h 42.859458 a 19.019216,18.358361 0 0 1 9.363921,2.267095 c 3.05508,1.657707 6.10575,4.372256 6.10575,8.578315 0.0177,8.420641 -1.6026,38.532103 -25.486988,38.532103 z M 70.87193,11.061694 A 1.7659438,1.7045832 0 0 1 69.476835,9.3869398 V 7.8528149 a 0.82116389,0.79263117 0 0 1 1.059566,-0.7628007 c 3.761459,1.0951944 16.749975,5.8722908 25.469325,21.5800238 0.004,0.01272 -11.14311,-14.46765 -25.133796,-17.595561 z"
            id="path16"
            style="fill: var(--action-colour); stroke-width: 0.433748"
          />
          <path
            class="cls-1"
            d="m 50.334002,49.977328 c -9.624393,0.686095 -10.741353,1.764244 -11.452145,11.054221 a 0.13686065,0.13210519 0 0 1 -0.269307,0 C 37.897343,51.741572 36.784798,50.663423 27.15599,49.977328 a 0.13686065,0.13210519 0 0 1 0,-0.259949 c 9.628808,-0.690356 10.741353,-1.764243 11.45656,-11.058484 a 0.13686065,0.13210519 0 0 1 0.269307,0 c 0.710792,9.294241 1.827752,10.368128 11.452145,11.058484 a 0.13686065,0.13210519 0 0 1 0,0.259949 z"
            id="path18"
            style="fill: var(--action-colour); stroke-width: 0.433748"
          />
          <path
            class="cls-1"
            d="m 57.093152,39.251238 c -6.052771,0.400578 -6.560481,0.894906 -6.975477,6.733104 a 0.03973374,0.03835312 0 0 1 -0.07505,0 c -0.414996,-5.838198 -0.92712,-6.332526 -6.975477,-6.733104 a 0.03973374,0.03835312 0 0 1 0,-0.07245 c 6.048357,-0.400577 6.560481,-0.894907 6.975477,-6.733105 a 0.03973374,0.03835312 0 0 1 0.07505,0 c 0.414996,5.838198 0.922706,6.332528 6.975477,6.733105 a 0.03973374,0.03835312 0 0 1 0,0.07245 z"
            id="path20"
            style="fill: var(--action-colour); stroke-width: 0.433748"
          />
          <path
            class="cls-1"
            d="m 99.100546,77.071678 -2.20743,3.268538 a 2.7416279,2.6463654 0 0 1 -0.88298,0.826723 C 92.332566,83.297667 70.845441,95.46413 57.984953,95.46413 45.124468,95.46413 22.251081,83.144255 18.357174,80.975173 A 2.6886496,2.5952279 0 0 1 17.434469,80.122881 L 15.257943,76.87139 a 1.1566932,1.116502 0 0 0 -0.966856,-0.507115 h -2.083813 a 1.7306251,1.6704916 0 0 0 -1.726211,1.670492 v 10.5812 a 1.7085507,1.6491842 0 0 0 1.055152,1.525601 c 5.911497,2.339541 34.992178,13.611092 46.687141,13.611092 11.694964,0 38.81545,-11.024387 44.563594,-13.410802 a 1.7659438,1.7045832 0 0 0 1.07722,-1.581002 V 78.188179 a 1.6776467,1.6193541 0 0 0 -1.67763,-1.623614 h -2.11473 a 1.1522784,1.1122405 0 0 0 -0.971264,0.507113 z m 2.900554,10.372388 a 1.1920121,1.1505936 0 0 1 -0.69754,1.02275 C 77.366189,99.708542 68.960295,99.546607 68.960295,99.546607 90.685816,92.557816 98.429486,87.904304 100.92829,86.029262 a 0.66222896,0.6392187 0 0 1 1.07281,0.507113 z"
            id="path22"
            style="fill: var(--action-colour); stroke-width: 0.433748"
          />
        </g>
      </svg>
      <router-link to="/store" class="link"
        ><font-awesome-icon
          :class="(currentPage == 'store' ? 'active' : '') + ' icon'"
          icon="fa-shop"
        />Store</router-link
      >
      <router-link to="/products" v-if="authenticated" class="link"
        ><font-awesome-icon
          :class="(currentPage == 'products' ? 'active' : '') + ' icon'"
          icon="fa-tag"
        />Products</router-link
      >
      <router-link to="/modifiers" v-if="authenticated" class="link"
        ><font-awesome-icon
          :class="(currentPage == 'modifiers' ? 'active' : '') + ' icon'"
          icon="fa-pen-to-square"
        />Modifiers</router-link
      >

      <router-link
        to="/orders"
        v-if="authenticated && siteSettings.futurePickup"
        class="link"
        ><font-awesome-icon
          :class="(currentPage == 'orders' ? 'active' : '') + ' icon'"
          icon="fa-solid fa-calendar-days"
        />Orders</router-link
      >
      <router-link
        to="/accounts"
        v-if="authenticated && siteSettings.accounts"
        class="link"
        ><font-awesome-icon
          :class="(currentPage == 'accounts' ? 'active' : '') + ' icon'"
          icon="fa-solid fa-file-invoice-dollar"
        />Accounts</router-link
      >
      <router-link
        to="/vouchers"
        v-if="authenticated && siteSettings.vouchers"
        class="link"
        ><font-awesome-icon
          :class="(currentPage == 'vouchers' ? 'active' : '') + ' icon'"
          icon="fa-solid fa-gift"
        />Vouchers</router-link
      >
      <router-link to="/totals" v-if="authenticated" class="link"
        ><font-awesome-icon
          :class="(currentPage == 'reports' ? 'active' : '') + ' icon'"
          icon="fa-money-bill-trend-up"
        />Reports</router-link
      >
    </div>
    <div class="settings-menu">
      <router-link to="/settings" v-if="authenticated" class="link"
        ><font-awesome-icon
          :class="(currentPage == 'settings' ? 'active' : '') + ' icon'"
          icon="fa-gear"
        />Settings</router-link
      >
      <div class="link" @click="logout" v-if="authenticated">
        <font-awesome-icon
          :class="' icon logout'"
          icon="fa-right-from-bracket"
        />Log Out
      </div>
    </div>
  </div>

  <div v-if="!webStore" class="responsive-menu">
    <ul :class="menuClass">
      <li>
        <router-link v-if="!authenticated" to="/login">Login</router-link>
      </li>
      <li><router-link to="/store" @click.capture.stop>Store</router-link></li>
      <li v-if="authenticated">
        <router-link to="/products" @click.capture.stop>Products</router-link>
      </li>
      <li v-if="authenticated">
        <router-link to="/modifiers" @click.capture.stop>Modifiers</router-link>
      </li>
      <li v-if="authenticated && siteSettings.futurePickup">
        <router-link to="/orders" @click.capture.stop>Orders</router-link>
      </li>

      <li v-if="authenticated">
        <router-link to="/totals" @click.capture.stop>Reports</router-link>
      </li>
      <li v-if="authenticated && siteSettings.accounts">
        <router-link to="/accounts" @click.capture.stop>Accounts</router-link>
      </li>
      <li v-if="authenticated && siteSettings.vouchers">
        <router-link to="/vouchers" @click.capture.stop>Vouchers</router-link>
      </li>
      <li v-if="authenticated">
        <router-link to="/settings" @click.capture.stop>Settings</router-link>
      </li>
      <li><a v-if="authenticated" @click="logout">Log Out</a></li>
    </ul>
    <font-awesome-icon
      :class="(socketConnected ? '' : 'unconnected') + ' robot'"
      @click="showMenu"
      icon="fa-solid fa-bars"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Menu",
  props: ["token", "collapseMenu"],
  emits: [],
  components: {},
  data() {
    return {
      site: "",
      authenticated: null,
      show: false,
      menuClass: "",
      // currentPage: 'store',
      // page: ''
      station: "",
    };
  },
  mounted() {
    this.authenticated = window.localStorage.getItem("token");
    this.site = window.localStorage.getItem("site");
    this.station = window.localStorage.getItem("station");
  },
  computed: {
    ...mapGetters({
      socketConnected: "socketConnected",
      webStore: "webStore",
      futurePickup: "futurePickup",
      currentPage: "currentPage",
      siteSettings: "siteSettings",
    }),
  },
  watch: {
    collapseMenu: {
      immediate: true,
      handler() {
        this.menuClass = "collapse";
      },
    },
    socketConnected: {
      // immediate: true,
      handler() {
        this.checkConnection();
      },
    },
  },
  methods: {
    checkConnection() {
      if (!this.webStore) {
        this.$notify({
          title: this.socketConnected
            ? "Connected to socket"
            : "Not connected to socket",
          type: this.socketConnected ? "success" : "error",
        });
      }
    },
    sendNote() {
      this.$notify({
        text:
          "Site is " +
          this.site +
          "<br /> Station is " +
          this.station +
          '<br /><a style="text-decoration: none; color: var(--secondary-colour);" href="/choose">Click here to change site</a>' +
          '<br /><a style="text-decoration: none; color: var(--secondary-colour);" href="/station">Click here to change station</a>',
        type: this.socketConnected ? "success" : "alert",
      });
    },
    showMenu() {
      this.menuClass = this.menuClass === "collapse" ? "menu" : "collapse";
    },
    async logout() {
      this.socket = this.$.appContext.config.globalProperties.socket;
      this.channel = this.$.appContext.config.globalProperties.channel;

      await this.channel.leave();
      await this.socket.disconnect();

      this.$root.token = null;
      window.localStorage.clear();
      //console.log(window.localStorage);
      //this.$router.push({ path: "/login" });
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/wrapper";
.responsive-menu {
  display: none;
}

#menu-wrapper {
  position: fixed;
  padding: 0.2rem 0rem 0.2rem 0.8rem;
  margin: 0.5rem 0rem 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;

  div {
    .active {
      color: var(--action-colour) !important;
    }

    .connected {
      color: #268729 !important;
    }

    .disconnected {
      color: #842a14 !important;
    }

    .icon {
      font-size: 2.5rem;
      height: 2.5rem;
      color: var(--text-colour);
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--text-colour);
  }

  list-style: none;

  .pages-menu,
  .settings-menu {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .settings-menu {
    margin-bottom: 20px;

    .logout {
      transform: rotate(180deg);
    }
  }
}

.menu {
  // border-width: 3px;
  border-radius: 0.5rem;
  width: 8rem;
}

hr {
  width: 75%;
  margin: auto;
}

.hamburger {
  display: none;
}

.robot {
  height: 3rem;
  align-self: flex-start;
  cursor: pointer;
  color: var(--action-colour);
  // transform: rotate(270deg);
}

.unconnected {
  color: var(--warning-colour);
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    font-size: 1.5em;
    text-decoration: none;
    color: var(--text-colour);
    display: block;
    // text-align: center;
    padding: 0;

    &:hover {
      // background-color: var(--banner-colour);
      // border-radius: 0.25rem;

      a,
      a:visited {
        color: #dfe7f0;

        cursor: pointer;
      }
    }

    a,
    a:visited {
      text-decoration: none;
      color: var(--text-colour);
      height: 100%;
      width: 100%;
      padding: 10px 0px 10px 0px;
      margin: 0rem 1rem;
      display: block;

      &:hover {
        color: var(--action-colour);
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .responsive-menu {
    display: flex;
    position: fixed;
    flex-direction: column;
    left: 1rem;
    bottom: 1rem;
    background-color: var(--secondary-colour);
    border: 0.25rem solid var(--primary-colour);
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 100000;

    .collapse {
      display: none;
    }
  }

  #menu-wrapper {
    display: none;

    .pages-menu,
    .settings-menu {
      display: none;
    }
  }

  // img { display: none; }

  .menu {
    width: 100%;
  }

  .ham {
    display: block;
    position: absolute;
    right: 1.5em;
    top: 0.4em;
    line-height: 1.5em;
    font-size: 1.5em;
  }

  .ham:before {
    content: "";
    position: absolute;
    top: 0.25em;
    left: 0;
    width: 1em;
    height: 0.125em;
    border-top: 0.375em double #000;
    border-bottom: 0.125em solid #000;
  }
}
</style>
