<template>
  <teleport to="body">
    <div v-if="open" class="modal" @click.self.prevent="open = ! open">
      <div class="wrapper">
	<div>
    
          <h2>Name: {{ order.name }}</h2>
          <h3>Notes: {{ order.notes }}</h3>
          <ul class="plist">
            <li v-for="product in order.products" v-bind:key="product.id">
          <div class="productBox">
            <h4>{{ product.quantity }} X {{ product.item }}</h4>
            <div v-for="mod in product.modifier_list" v-bind:key="mod">
              <p>{{ mod.name }}</p>
            </div>
          </div>
            </li>
          </ul>
	</div>
        <div class="content">
          <button @click="load">Load Order</button>
          <button  @click="emailOpen = ! emailOpen">Email Receipt</button>
          <button v-if="!account && print" @click="print">Print Receipt</button>
          <button @click="sendQR">QR Code to Companion</button>
        </div>

          <h4 v-if="this.emailOpen">E-mail</h4>
          <div v-if="this.emailOpen" class="email">
            <input type="text" v-model="enteredEmail" placeholder="Customer Email..." required/>
            <button @click="email(enteredEmail)">Send</button>
          </div>

        <div class="actions">
          <button class="action cancel" @click.prevent.stop="cancel">Cancel</button>
        </div>

      </div>

    </div>
  </teleport>
</template>

<script>
//import HistoryOrder from "@/components/History/HistoryOrder";

export default {
  name: 'DualButton',
  //components: {
  //  HistoryOrder
  //},
  props: ['show', 'order', 'account'],
  emits: ['load', 'email', 'print'],
  data() {
    return {
      open: false,
      result: null,
      emailOpen:false,
      enteredEmail: null
    }
  },
  methods: {
    cancel() {
      this.open = ! this.open;
    },
    email(enteredEmail) {
      this.$emit('email', this.order, enteredEmail);
      this.open = false;
      
    },
    load() {
      this.$emit('load', this.order);
      this.open = false;
    },
    print() {
      this.$emit('print', this.order);
      this.open = false;
    },
    sendQR() {
      this.channel.push("qrcode:show", this.order);
      this.open = false;
    }
  },
  watch: {
    show() {
      this.emailOpen = false;
      this.open = true;
    }
  }
}

</script>

<style lang="scss" scoped>
@import "public/wrapper";

.modal {


  input{
    font-family: "Lato";
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    width: calc(100% - 2.5rem);
    padding: 1rem 1rem 1rem 1rem;
    font-size: 1.5rem;
  }

  .wrapper {
    background-color: var(--secondary-colour);
    padding: 1rem;
    min-width: 60%;
    max-width: 60%;
    color: var(--primary-text-colour);
    border: 3px;
    border-style: solid;
    border-color: var(--secondary-colour);
    border-radius: 0.25rem;
  }

  div.content {
    display: flex;
    flex-direction: row;
    min-height: 4rem;
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: space-around;
    h3.title {
      margin:0px 0px 1rem 0px;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ccc;
    }

    label { font-weight: bold; margin-bottom: 0.25rem;}

    button { margin: 1rem; width: 100%;}
    textarea { height: 4rem; }

    img {
      display: block;
      width: 200px;
    }

  }
  ul{
    padding: 0;
  }

  ul.plist {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-flow: row;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }
  
  div.productBox {
      flex: auto;
      border: 3px;
      border-style: solid;
      padding: 10px;
      background-color: var(--primary-colour);
      color: var(--text-colour);
      border-color: var(--primary-colour);
      border-radius: 0.25rem;
      min-height: 7rem;

      h4{
        margin-block-start: 0rem;

      }
  }

  div li {
      list-style: none;
  }
  
  .cancel{
    color: var(--secondary-text-colour);
  }
}


</style>
