<template>
  <div v-if="webStore" class="header"><Header /></div>
  <div :class="!webStore ? 'outer-container' : ''">
    <Menu :collapseMenu="$route.path === '/store'" />
    <div id="content"><slot /></div>
  </div>
</template>

<script>
import Menu from "@/components/_layouts/Menu";
import Header from "@/components/_layouts/Header";
import { mapGetters } from "vuex";

export default {
  components: { Menu, Header },
  name: "Authenticated",
  computed: {
    ...mapGetters({
      loading: "loading",
      socketConnected: "socketConnected",
      webStore: "webStore",
    }),
  },
  watch: {
    // '': {
    //     handler() {
    //       //console.log('State change');
    //     }
    // }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.outer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: calc(100vh);

  #content {
    width: 100vw;
    margin-left: 5rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--banner-colour);
  color: #eeeff1;
  justify-content: center;
}

@media only screen and (orientation: portrait) {
  .outer-container {
    flex-direction: column;

    #content {
      margin-left: 0rem;
    }
  }

  #menu {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
